import React, { useEffect, useState } from 'react'
import { GoArrowUpRight } from 'react-icons/go'
import { HiMenuAlt3, HiX } from 'react-icons/hi'
import { IoChevronDownOutline } from 'react-icons/io5'
import { PiTranslate } from 'react-icons/pi'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Modal from '../Modal'
import RotatingText from './RotatingText'
import { useLocale } from '../../contexts/useLocale'
import {
  fetchHeaderTexts,
  HeaderSectionTexts,
} from '../../services/strapiService'

interface HeaderProps {
  setModalOpen: (isOpen: boolean) => void
}

const flagPath: Record<string, React.JSX.Element> = {
  'pt-BR': (
    <img
      src={require('../../assets/countries_flags/brasil.png')}
      alt='Português'
    />
  ),
  en: (
    <img
      src={require('../../assets/countries_flags/estados-unidos.png')}
      alt='English'
    />
  ),
  es: (
    <img
      src={require('../../assets/countries_flags/espanha.png')}
      alt='Espanhol'
    />
  ),
  'de-DE': (
    <img
      src={require('../../assets/countries_flags/alemanha.png')}
      alt='Alemão'
    />
  ),
  ja: (
    <img
      src={require('../../assets/countries_flags/japao.png')}
      alt='Japonês'
    />
  ),
  'zh-Hans': (
    <img src={require('../../assets/countries_flags/china.png')} alt='Chinês' />
  ),
}

const Header: React.FC<HeaderProps> = () => {
  const { locale, changeLocale } = useLocale()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [activeLanguage, setActiveLanguage] = useState(locale)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [headerTexts, setHeaderTexts] = useState<HeaderSectionTexts>(
    {} as HeaderSectionTexts
  )

  const navigate = useNavigate()
  const { pathname, hash } = useLocation()

  const handleLanguageChange = (language: string) => {
    changeLocale(language)
    setActiveLanguage(language)
    setIsDropdownOpen(false)
  }

  const handleTranslatorButtonClick = () => {
    navigate('/tradutores')
  }

  const handleRequestQuoteButtonClick = () => {
    setIsModalOpen(true)
  }

  const getHeaderTexts = async () => {
    try {
      const { data } = await fetchHeaderTexts(locale)

      setHeaderTexts(data?.data)
    } catch (error) {
      console.error('Erro ao carregar informações do header', error)
    }
  }

  useEffect(() => {
    getHeaderTexts()
  }, [locale])

  return (
    <HeaderContainer>
      <TopBar>
        <RotatingText />
        <LanguageSelector>
          <LanguageButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {flagPath[activeLanguage]}
            <IoChevronDownOutline />
          </LanguageButton>
          {isDropdownOpen && (
            <LanguageDropdown>
              {activeLanguage !== 'pt-BR' && (
                <LanguageItem onClick={() => handleLanguageChange('pt-BR')}>
                  <img
                    src={require('../../assets/countries_flags/brasil.png')}
                    alt='Português'
                  />
                </LanguageItem>
              )}
              {activeLanguage !== 'en' && (
                <LanguageItem onClick={() => handleLanguageChange('en')}>
                  {flagPath['en']}
                </LanguageItem>
              )}
              {activeLanguage !== 'es' && (
                <LanguageItem onClick={() => handleLanguageChange('es')}>
                  {flagPath['es']}
                </LanguageItem>
              )}
              {activeLanguage !== 'de-DE' && (
                <LanguageItem onClick={() => handleLanguageChange('de-DE')}>
                  {flagPath['de-DE']}
                </LanguageItem>
              )}
              {activeLanguage !== 'ja' && (
                <LanguageItem onClick={() => handleLanguageChange('ja')}>
                  {flagPath['ja']}
                </LanguageItem>
              )}
              {activeLanguage !== 'zh-Hans' && (
                <LanguageItem onClick={() => handleLanguageChange('zh-Hans')}>
                  {flagPath['zh-Hans']}
                </LanguageItem>
              )}
            </LanguageDropdown>
          )}
        </LanguageSelector>
      </TopBar>

      <Navbar>
        <NavbarTop>
          <Logo href='/'>
            <img src='../../header/logo.svg' alt='GL Traduções Logo' />
          </Logo>
          <MenuToggle onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <HiX size={30} /> : <HiMenuAlt3 size={30} />}
          </MenuToggle>
        </NavbarTop>

        <NavLinks isMenuOpen={isMenuOpen}>
          {headerTexts?.list?.map((item) => {
            if (item?.href === '/#como-funciona' && pathname !== '/')
              return null

            return (
              <React.Fragment key={item?.title}>
                <li>
                  <NavLabelLink path={pathname + hash} href={item?.href}>
                    {item?.title}
                  </NavLabelLink>
                </li>
              </React.Fragment>
            )
          })}
        </NavLinks>

        <ButtonsWrapper isMenuOpen={isMenuOpen}>
          <Button primary onClick={handleRequestQuoteButtonClick}>
            {headerTexts?.buttonLabelRequestQuote} <GoArrowUpRight />
          </Button>
          <Button onClick={handleTranslatorButtonClick}>
            {headerTexts?.buttonLabelBeTranslator} <PiTranslate />
          </Button>
        </ButtonsWrapper>
      </Navbar>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        serviceType={{ value: 'Tradução Técnica', label: 'Tradução Técnica' }}
      />
    </HeaderContainer>
  )
}

export default Header

const HeaderContainer = styled.header`
  width: 100%;
  background-color: var(--grey);
  font-family: 'Inter', sans-serif;
`

const TopBar = styled.div`
  background-color: var(--hard-blue);
  color: white;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  @media (min-width: 769px) {
    font-size: 16px;
    padding: 10px 20px;
  }
`

const LanguageSelector = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const LanguageButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;

  img {
    width: 24px;
    height: auto;
  }
`

const LanguageDropdown = styled.div`
  top: 0;
  left: -150%;
  display: flex;
  gap: 5px;
  z-index: 1000;
`

const LanguageItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--soft-blue);
  }

  img {
    width: 34px;
    height: auto;
  }
`

const Navbar = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: var(--grey);

  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const NavbarTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const MenuToggle = styled.div`
  display: block;
  cursor: pointer;
  color: var(--hard-blue);

  @media (min-width: 769px) {
    display: none;
  }
`

const NavLinks = styled.ul<{ isMenuOpen: boolean }>`
  display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
  flex-direction: column;
  list-style: none;
  gap: 20px;
  width: 100%;
  padding: ${(props) => (props.isMenuOpen ? '40px 0px;' : '0px 20px;')};
  margin-right: ${(props) => (props.isMenuOpen ? '30px;' : '50px;')};
  background: var(--grey);
  transition: all 0.3s ease;

  @media (min-width: 769px) {
    flex-direction: row;
    display: flex;
    gap: 20px;
    width: auto;
    padding: 0;
  }

  li {
    height: 50px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    transition: all 0.3s ease;
  }
`

const NavLabelLink = styled.a<{ path: string; hash?: string }>`
  color: ${({ path, hash, href }) =>
    path === href && !hash ? 'var(--soft-blue)' : 'var(--black)'};
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;

  &:hover {
    color: var(--soft-blue);
  }

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`

const Logo = styled.a`
  width: 180px;
  img {
    width: 100%;
  }
`

const ButtonsWrapper = styled.div<{ isMenuOpen: boolean }>`
  display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  padding: 20px 0;

  @media (min-width: 769px) {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: auto;
    padding: 0;
  }
`

const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) =>
    props.primary ? 'var(--hard-blue)' : 'transparent'};
  color: ${(props) => (props.primary ? 'white' : 'var(--hard-blue)')};
  border: ${(props) => (props.primary ? 'none' : '1px solid var(--hard-blue)')};
  padding: 16px 21px;
  white-space: nowrap;
  font-size: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.primary ? 'var(--soft-blue)' : 'var(--hard-blue)'};
    color: ${(props) => (props.primary ? 'white' : 'white')};
  }

  svg {
    font-size: 18px;
  }
`
